import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

const FeaturesPage = () => {
  const features = [
    {
      title: "AI-Powered Content Generation",
      description: "Leverage advanced AI algorithms to transform your raw content into professional, engaging LinkedIn posts.",
      details: `In today’s competitive digital landscape, creating impactful content that resonates with your audience is essential. Our AI-Powered Content Generation tool is designed to help you achieve just that. By harnessing advanced AI algorithms, our tool analyzes your input and transforms it into polished, engaging posts tailored specifically for LinkedIn.
  
      This means you can take a rough draft or even a few bullet points and turn them into a fully realized post that captures attention and encourages interaction. The AI adapts to your unique writing style, ensuring that the final output remains authentic while enhancing clarity and engagement. Additionally, it identifies trending topics and optimal posting formats, ensuring your content is not only relevant but also positioned for maximum visibility.
  
      With our tool, you can significantly reduce the time spent on content creation. Instead of wrestling with how to phrase your thoughts or structure your message, you can focus on delivering value and building connections. This is more than just a content generator; it's a strategic partner in enhancing your professional presence on LinkedIn. Experience the power of AI and take your content creation to new heights, ensuring your posts are impactful and effective in engaging your network.`,
      image: "./feature1.webp",
    },
    {
      title: "Optimized Tags for Maximum Engagement",
      description: "Automatically generate relevant tags designed to increase visibility and engagement on LinkedIn.",
      details: `The right tags can make all the difference in your content's reach and engagement on LinkedIn. Our tool automatically generates optimized tags tailored to your content, ensuring that your posts reach the right audience. It analyzes your text, identifies key themes, and suggests relevant tags that are currently trending within your industry.
  
      By using these intelligently generated tags, you enhance the discoverability of your content, allowing more users to find and engage with your posts. This feature takes the guesswork out of tagging, freeing you from the need to research what tags are popular or effective. Instead, you can focus on crafting your message, knowing that the tool is supporting you in maximizing your content's visibility.
  
      Our tag optimization process is rooted in data analysis, ensuring that each suggestion is not only relevant but also aligned with current user behavior and trends. This way, you can be confident that the tags you’re using are helping to drive engagement, spark conversations, and expand your network on LinkedIn. Embrace the power of optimized tagging and watch your content's reach and impact grow as you connect with like-minded professionals and industry leaders.`,
      image: "./feature2.webp",
    },
    {
      title: "Enhanced Keywords for Higher Traffic",
      description: "Improve your content’s searchability with targeted keywords that attract more views and interactions.",
      details: `Keywords play a crucial role in making your content searchable and accessible. Our tool enhances your posts by identifying and integrating high-traffic keywords that resonate with your audience. By ensuring your content is rich with relevant keywords, you increase the chances of being discovered by users who are searching for topics related to your expertise.
  
      This feature analyzes current trends and user behavior to suggest the most effective keywords for your specific content. Whether you are discussing industry trends, sharing insights, or providing valuable tips, incorporating these keywords will enhance your visibility on LinkedIn. The result is not just increased views but also greater engagement and interaction with your posts.
  
      Our keyword enhancement process is designed to seamlessly integrate into your writing, ensuring that your content remains authentic while being optimized for search. You can focus on delivering value to your audience, knowing that the keywords are working in the background to attract more traffic and conversations. Embrace this powerful feature and watch as your content reaches a wider audience, helping you to establish yourself as a thought leader in your industry.`,
      image: "./feature3.webp",
    },
    {
      title: "User-Friendly Interface",
      description: "Experience an intuitive design that simplifies content creation and enhances productivity.",
      details: `Navigating content creation should be simple and intuitive, and our tool is designed with that principle in mind. The user-friendly interface allows you to easily create and manage your posts without the hassle of complicated navigation. From the moment you log in, you will find a clean layout that prioritizes efficiency and ease of use.
  
      This intuitive design allows you to focus on your writing, minimizing distractions and streamlining the entire content creation process. With clearly labeled features and tools, you can quickly find what you need, whether it’s drafting a new post, adding tags, or analyzing your content’s performance. 
  
      We also incorporate helpful tutorials and tips directly into the platform, providing real-time guidance as you work. This support ensures that users of all experience levels can take full advantage of our powerful features without feeling overwhelmed. The result is a tool that enhances your productivity and allows you to concentrate on what truly matters: crafting impactful content and connecting with your audience. Experience the ease of creating professional posts with our user-friendly interface and elevate your LinkedIn presence effortlessly.`,
      image: "./feature4.webp",
    },
    {
      title: "Real-Time Content Suggestions",
      description: "Receive instant feedback and suggestions as you write, ensuring your content remains engaging and relevant.",
      details: `In a fast-paced digital environment, being able to adapt your content in real-time is invaluable. Our real-time content suggestions feature offers instant feedback as you write, allowing you to refine your posts on-the-go. This functionality ensures that your content not only meets high standards but also aligns with best practices for LinkedIn engagement.
  
      As you compose your post, the tool analyzes your writing and provides suggestions for improvement, whether it’s adjusting the tone, refining your structure, or enhancing clarity. This dynamic feedback loop empowers you to make adjustments in real-time, maximizing the impact of your message.
  
      By incorporating suggestions based on current trends and user engagement metrics, our tool helps you to stay relevant and appealing to your audience. This proactive approach means you can enhance your writing while you create, leading to posts that are not only well-crafted but also more likely to resonate with your network.
  
      With real-time content suggestions, you gain the confidence to experiment with your writing style and messaging, knowing that you have an intelligent system supporting you. This feature transforms the writing process into an interactive experience, allowing you to engage with your audience in a more meaningful way.`,
      image: "./feature5.webp",
    },
    {
      title: "Analytics Dashboard",
      description: "Gain insights into your content performance with comprehensive analytics.",
      details: `Understanding the effectiveness of your content is essential for continuous improvement. Our analytics dashboard provides comprehensive insights into how your posts are performing, allowing you to track engagement, reach, and conversion rates over time.
  
      With this wealth of data at your fingertips, you can identify trends, understand what resonates with your audience, and refine your content strategy accordingly. The dashboard presents metrics in an easy-to-understand format, enabling you to make informed decisions based on real-time feedback.
  
      You can monitor individual post performance or analyze overall trends across your content. This visibility helps you to identify which types of content drive the most engagement, allowing you to replicate successful strategies in the future.
  
      By leveraging our analytics dashboard, you gain valuable insights that empower you to enhance your LinkedIn strategy. This feature not only helps you to understand your audience better but also guides your content creation efforts towards greater effectiveness, ultimately leading to improved results in your networking and engagement efforts.`,
      image: "./feature6.webp",
    },
  ];
  
  
  return (
    <Container className="my-5">
      <Row className="justify-content-center mb-5">
        <Col md={8}>
          <h1 className="text-center mb-4">Our Features</h1>
          <p className="text-center">
            Discover the powerful features that make our content generation app the perfect choice for elevating your LinkedIn presence. Each feature is designed to help you create engaging, high-traffic posts effortlessly.
          </p>
        </Col>
      </Row>

      {features.map((feature, index) => (
        <Row key={index} className="align-items-center mb-5">
          <Col md={6}>
            <Image src={feature.image} alt={feature.title} fluid className="rounded" />
          </Col>
          <Col md={6}>
            <Card className="p-4">
              <Card.Body>
                <Card.Title as="h3">{feature.title}</Card.Title>
                <Card.Text>{feature.description}</Card.Text>
                <p>{feature.details}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default FeaturesPage;
