import React from 'react';
import { Container } from 'react-bootstrap';

const PrivacyPolicyPage = () => {
  return (
    <Container className="my-5">
      <h1>Privacy Policy</h1>

      <h3>Introduction</h3>
      <p>
        Welcome to <strong>Your Content Generation Tool</strong>! We respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we handle, use, and safeguard your information when you use our service. By accessing our website <strong>[www.yourwebsite.com]</strong>, you consent to the practices described in this policy.
      </p>

      <h3>Information Collection and Use</h3>
      <p>
        We collect various types of information to enhance your experience with our content generation tool. The types of data we collect include:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Information you provide to us directly, such as your name, email address, and other details necessary to create and manage your account.
        </li>
        <li>
          <strong>Usage Data:</strong> Data automatically collected when using our service, including your IP address, browser type, and information about how you use our content generation tool.
        </li>
        <li>
          <strong>Cookies and Tracking Data:</strong> We may use cookies and similar tracking technologies to monitor activity on our service and store certain information to enhance your user experience.
        </li>
      </ul>

      <h3>Purpose of Data Collection</h3>
      <p>
        We collect and use your data for various purposes, including but not limited to:
      </p>
      <ul>
        <li>Providing and maintaining our content generation service</li>
        <li>Improving customer experience and understanding user needs</li>
        <li>Notifying you about updates or changes to our service</li>
        <li>Providing customer support and responding to your inquiries</li>
        <li>Analyzing and enhancing the functionality of our tool</li>
        <li>Ensuring security and preventing misuse or fraud</li>
      </ul>

      <h3>Data Sharing and Disclosure</h3>
      <p>
        Your privacy is essential to us, and we do not sell or share your personal data with third parties except in the following cases:
      </p>
      <ul>
        <li><strong>Service Providers:</strong> We may share data with trusted service providers to facilitate our operations, but only to the extent necessary and under confidentiality obligations.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required to comply with legal obligations or in response to valid requests by public authorities.</li>
        <li><strong>Business Transfers:</strong> If our company undergoes a merger, acquisition, or asset sale, your data may be transferred to the acquiring entity.</li>
      </ul>

      <h3>Security of Your Data</h3>
      <p>
        We prioritize the security of your data. Although we use advanced measures to protect your information, no method of transmission over the internet is completely secure. We continuously work to implement commercially acceptable standards to safeguard your data.
      </p>

      <h3>Your Data Protection Rights</h3>
      <p>
        Depending on your location, you may have the right to:
      </p>
      <ul>
        <li>Access, update, or delete your personal information</li>
        <li>Withdraw consent where we rely on it for processing</li>
        <li>Request restriction or object to the processing of your data</li>
        <li>Request data portability for a copy of your data</li>
      </ul>
      <p>
        If you would like to exercise any of these rights, please contact us at the email address below.
      </p>

      <h3>Cookies and Tracking Technologies</h3>
      <p>
        Our service uses cookies and similar tracking technologies to monitor activity and improve user experience. You can instruct your browser to refuse cookies or indicate when a cookie is being sent. However, if you do not accept cookies, some parts of our service may not function properly.
      </p>

      <h3>Changes to This Privacy Policy</h3>
      <p>
        We may update this Privacy Policy periodically. You are encouraged to review this page for any changes. Changes will become effective when posted on this page, and we will notify you via email or a prominent notice on our service if they are significant.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us at:
      </p>
      <ul>
        <li><strong>Email:</strong> <a href="mailto:tekvenza@gmail.com">tekvenza@gmail.com</a></li>
        {/* <li><strong>Address:</strong> 123 SocialMystic Street, Suite 100, City, State, ZIP Code</li>
        <li><strong>Phone:</strong> +1 234 567 890</li> */}
      </ul>
    </Container>
  );
};

export default PrivacyPolicyPage;
