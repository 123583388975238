import React from "react";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <Container className="my-5">
      <Row className="justify-content-center mb-5">
        <Col md={8}>
          <h1>About Us</h1>
          <p>
            Welcome to <strong>SocialMystic</strong>, where creativity and
            technology converge to bring out the best in professional
            networking. We believe in the power of impactful content and its
            potential to build connections, drive engagement, and foster growth.
            Our journey began with a simple yet ambitious vision: to empower
            professionals with tools that amplify their voice and presence on
            LinkedIn.
          </p>
          <p>
            Today, we are proud to offer an advanced LinkedIn content generation
            platform designed to transform how professionals present themselves.
            By focusing on tone refinement, engagement-driven keywords, and
            optimized tags, we make it easier for users to craft posts that
            resonate with their audience. We are continuously innovating to stay
            ahead of industry trends, ensuring that our solutions provide
            unmatched value and relevance.
          </p>
          <p>
            Join us as we redefine professional networking, making it
            accessible, authentic, and impactful. Together, we can unlock the
            potential of every LinkedIn post, turning connections into lasting
            partnerships.
          </p>
          <Image
            src="./about1.webp"
            fluid
            className="my-4"
          />
        </Col>
      </Row>

      {/* Our Mission */}
      <Row className="justify-content-center mt-4">
        <Col md={8}>
          <Card className="p-4">
            <Card.Body>
              <h3>Our Mission</h3>
              <p>
                At <strong>Our Company</strong>, our mission is to empower users
                by transforming complex challenges into simple, effective
                solutions. We believe in crafting tools that are not only
                powerful but also intuitive, enabling users to excel with
                minimal effort.
              </p>
              <p>
                We go beyond delivering services—we build partnerships. Every
                feature we design and every enhancement we implement stems from
                our deep commitment to our users' success. By streamlining
                workflows and enhancing productivity, we strive to create
                meaningful impact, helping individuals and organizations unlock
                new levels of efficiency and opportunity.
              </p>
              <Image
                src="./about2.webp"
                fluid
                className="my-4"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Our Vision */}
      <Row className="justify-content-center mt-4">
        <Col md={8}>
          <Card className="p-4">
            <Card.Body>
              <h3>Our Vision</h3>
              <p>
                Our vision is to create a world where technology effortlessly
                supports both individuals and organizations, freeing them to
                focus on what truly matters. We strive to develop tools that
                enhance productivity and streamline complexities, enabling our
                users to accomplish more with less.
              </p>
              <p>
                In a fast-paced, technology-driven landscape, we believe in a
                balanced approach that values both innovation and human-centered
                design. Our goal is to empower users with solutions that
                integrate seamlessly into their lives, promoting a sense of
                purpose, efficiency, and simplicity. Whether it's automating
                routine tasks or simplifying intricate workflows, we aim to make
                a positive impact—one innovative solution at a time.
              </p>
              <Image
                src="./about3.webp"
                fluid
                className="my-4"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Core Values */}
      <Row className="justify-content-center mt-4">
        <Col md={8}>
          <Card className="p-4">
            <Card.Body>
              <h3>Our Core Values</h3>
              <p>
                Our core values are the guiding principles that shape our
                identity and drive our every endeavor. Built on a foundation of
                integrity, commitment to quality, and an unwavering focus on the
                user, these values inspire us to create meaningful, impactful
                solutions for our customers. Through them, we forge lasting
                relationships and drive forward with purpose.
              </p>
              <ul>
                <li>
                  <strong>Integrity:</strong> We hold ourselves to the highest
                  ethical standards, doing what’s right even when no one is
                  watching. This honesty and transparency create a foundation of
                  trust and reliability in everything we do.
                </li>
                <li>
                  <strong>Excellence:</strong> Quality is at the core of our
                  mission, reflected in our attention to detail, robust
                  solutions, and commitment to delivering excellence from
                  initial development to ongoing customer support.
                </li>
                <li>
                  <strong>Innovation:</strong> We strive to stay ahead of the
                  curve, constantly seeking innovative ways to meet and
                  anticipate the evolving needs of our users, ensuring our tools
                  remain relevant and powerful.
                </li>
                <li>
                  <strong>Empathy:</strong> Listening to our users,
                  understanding their challenges, and placing their needs at the
                  forefront allows us to create solutions that truly resonate,
                  making a difference in their lives and work.
                </li>
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Meet the Team */}
      {/* <Row className="justify-content-center mt-4">
        <Col md={8}>
          <Card className="p-4">
            <Card.Body>
              <h3>Meet the Team</h3>
              <p>
                Behind every great product is a team of dedicated professionals.
                Our experts come together with diverse backgrounds in
                development, design, marketing, and strategy. They are
                passionate about innovation and committed to delivering
                top-quality solutions that make a meaningful impact in the lives
                of our users.
              </p>
              <Row>
                <Col md={4} className="text-center">
                  <Image
                    src="./about4.webp"
                    roundedCircle
                    fluid
                  />
                  <h5 className="mt-3">John Doe</h5>
                  <p>CEO & Founder</p>
                  <p>
                    With over a decade of experience in the tech industry, John
                    leads our vision, ensuring we remain at the forefront of
                    innovation and excellence.
                  </p>
                </Col>
                <Col md={4} className="text-center">
                  <Image
                    src="https://via.placeholder.com/200x200"
                    roundedCircle
                    fluid
                  />
                  <h5 className="mt-3">Jane Smith</h5>
                  <p>CTO & Co-Founder</p>
                  <p>
                    Jane's technical expertise drives our product development.
                    Her passion for technology and user experience ensures our
                    solutions are both powerful and intuitive.
                  </p>
                </Col>
                <Col md={4} className="text-center">
                  <Image
                    src="https://via.placeholder.com/200x200"
                    roundedCircle
                    fluid
                  />
                  <h5 className="mt-3">Michael Lee</h5>
                  <p>Head of Product</p>
                  <p>
                    Michael combines creativity and strategy, guiding our
                    product vision to meet user needs effectively and
                    efficiently, while enhancing engagement and satisfaction.
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      {/* Commitment to Quality */}
      <Row className="justify-content-center mt-4">
        <Col md={8}>
          <Card className="p-4">
            <Card.Body>
              <h3>Our Commitment to Quality</h3>
              <p>
                At <strong>SocialMystic</strong>, quality is not just a goal;
                it's a core value that drives every aspect of our work. From the
                initial design phase through to final delivery, we meticulously
                ensure that our solutions adhere to the highest standards of
                excellence. Our dedicated quality assurance team rigorously
                tests each product to identify and rectify any potential issues
                before launch.
              </p>
              <p>
                We understand that quality is the foundation of trust, and we
                strive to build that trust with every interaction. Feedback from
                our users is invaluable, and we actively incorporate it to
                refine our products continually. We believe that our users
                deserve nothing less than the best, and our commitment to
                quality reflects our respect for their needs and expectations.
              </p>
              <p>
                By upholding our commitment to quality, we not only enhance user
                satisfaction but also foster lasting relationships. We take
                pride in our work, knowing that it plays a significant role in
                our users' success.
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Call to Action */}
      <Row className="justify-content-center mt-5">
        <Col md={8} className="text-center">
          <h2>Join Us on Our Journey</h2>
          <p>
            Are you ready to take the next step? We invite you to connect with
            us and explore how we can collaborate to create impactful solutions
            together. Whether you have questions, suggestions, or want to
            discuss potential partnerships, we are here to listen.
          </p>
          <Button variant="primary" size="lg" as={Link} to="/contact">
            Get in Touch
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutPage;
