import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

const ImagePreview = ({ imageUrl, altText }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false); // Set loading to false once the image is fully loaded
  };

  return (
    <div style={{ position: "relative", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", minHeight: "300px" }}>
      {/* Spinner centered in the container while loading */}
      {isLoading && (
        <div style={{ position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {/* Image Element */}
      <img
        src={imageUrl}
        alt={altText}
        onLoad={handleImageLoad} // Hide spinner when image loads
        style={{
          maxWidth: "100%",
          height: "auto",
          borderRadius: "8px",
          display: isLoading ? "none" : "block", // Hide image until fully loaded
          margin: "auto" // Center the image
        }}
      />

      {/* Download Button shown after image is fully loaded */}
      {!isLoading && (
        <a
          href={imageUrl}
          download="downloaded_image.png"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "#fff",
            padding: "0.5rem 1rem",
            borderRadius: "5px",
            fontWeight: "bold",
            textDecoration: "none",
          }}
        >
          Download Image
        </a>
      )}
    </div>
  );
};

export default ImagePreview;
