import React from 'react';
import { Container, Row, Col, Form, Button, Card, Image } from 'react-bootstrap';

const ContactUsPage = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Form submitted!');
  };

  return (
    <Container className="my-5">
      {/* Contact Us Heading and Description */}
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="text-center mb-4">Contact Us</h1>
          <p className="text-center">
            Have questions or need support? We’re here to help! Please fill out the form below, and we’ll get back to you as soon as possible.
          </p>
          <p className="text-center">
            Need assistance or have inquiries? Our team is ready to provide the support you need! Simply complete the form below, and we’ll reach out promptly. For an even quicker response, feel free to email us directly!
          </p>
        </Col>
      </Row>

      {/* Contact Information Section */}
      <Row className="justify-content-center mt-5">
        <Col md={4} className="text-center">
          <Card className="p-4 mb-4">
            <Card.Body>
              <Image src="./c1.webp" roundedCircle className="mb-3" style={{ width: '100px', height: '100px' }}/>
              <h5>Customer Support</h5>
              <p>Email: tekvenza@gmail.com</p>
              {/* <p>Phone: +1 234 567 890</p> */}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="text-center">
          <Card className="p-4 mb-4">
            <Card.Body>
              <Image src="./c2.webp" roundedCircle className="mb-3" style={{ width: '100px', height: '100px' }}/>
              <h5>Sales Inquiry</h5>
              <p>Email: tekvenza@gmail.com</p>
              {/* <p>Phone: +1 234 567 891</p> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Contact Form Section */}
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="p-4 shadow">
            <h2 className="text-center mb-4">Send Us a Message</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" required />
              </Form.Group>

              <Form.Group controlId="formSubject" className="mb-3">
                <Form.Label>Subject</Form.Label>
                <Form.Control type="text" placeholder="Enter subject" required />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={5} placeholder="Enter your message" required />
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100">
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>

      {/* Location Section */}
      {/* <Row className="justify-content-center mt-5">
        <Col md={8} className="text-center">
          <h3>Our Office Location</h3>
          <p>
            123 SocialMystic Road, Suite 100 <br />
            City, State, Zip Code <br />
            <strong>Open Hours:</strong> Monday - Friday, 9:00 AM - 5:00 PM
          </p>
          <Image src="https://via.placeholder.com/800x400" alt="Office Location" className="img-fluid rounded" />
        </Col>
      </Row> */}
    </Container>
  );
};

export default ContactUsPage;
