import React from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "./Header";
import ContentGenerator from "./ContentGenerator";
import "./LandingPage.css";

const LandingPage = () => {

  const heroSection = () => {
    return (
      <section
        className="d-none d-md-block" // Hides section on mobile and shows on medium and larger screens
        style={{
          position: "relative",
          overflow: "hidden",
          height: "60vh",
          color: "white",
        }}
      >
        {/* Background Video */}
        <video
          autoPlay
          muted
          loop
          playsInline
          poster={`${process.env.PUBLIC_URL}/thumbnail.webp`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        >
          <source
            src={`https://marketting-assets.s3.ap-south-1.amazonaws.com/social_mystic_demo.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
  
        {/* Optional Content */}
        {/* <Container className="text-center">
          <h1>Welcome to SocialMystic</h1>
          <p className="lead">Transforming Your LinkedIn Presence with Engaging, Optimized Content</p>
        </Container> */}
      </section>
    );
  }
  return (
    <>

      {/* Header with Navigation */}
      <Header />

      {/* Hero Section with Image and Main Content */}
      {/* <section
        style={{
          backgroundImage: `url(https://via.placeholder.com/1920x500)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '100px 0',
          color: 'white',
        }}
      >
        <Container className="text-center">
          <h1>Welcome to SocialMystic</h1>
          <p className="lead">Simplifying your work with smart solutions.</p>
          <Button variant="light" size="lg">Get Started</Button>
        </Container>
      </section> */}

            {/* Hero Section with Image and Main Content */}
            {heroSection()}


      <Container fluid className="main">
          <Row className="justify-content-center">
              <Col xs={12} md={10} lg={8}>
                  <ContentGenerator />
              </Col>
          </Row>
      </Container>

      {/* Video Section */}
      {/* <section id="video" className="my-5">
        <Container>
          <Row className="text-center mb-4">
            <Col>
              <h2>See Our Solution in Action</h2>
              <p>Watch our video to see how our product can streamline your hiring process.</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8}>
              <div className="video-container">
                <video
                  src="https://marketting-assets.s3.ap-south-1.amazonaws.com/social_mystic_demo.mp4"
                  title="Product Demo Video"
                  className="video-element"
                  controls
                  // autoPlay
                  loop
                  muted
                  poster="./video_snapshot.webp"
                ></video>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* Features Section with Side-by-Side Layout */}
      <section id="features" className="my-5">
        <Container>
          <Row className="text-center mb-4">
            <Col>
              <h2>Our Features</h2>
              <p>
                Explore the powerful tools we provide to make your work
                efficient and productive.
              </p>
            </Col>
          </Row>

          {/* Feature Rows */}
          <Row className="align-items-center mb-5">
            <Col md={6}>
              <img
                src="./f1.webp"
                alt="Transform Content to a Professional, Engaging Tone"
                className="img-fluid rounded"
              />
            </Col>
            <Col md={6}>
              <h3>Transform Content to a Professional, Engaging Tone</h3>
              <p>
                In the competitive world of LinkedIn, content is king. But it's
                not enough to just post – the tone of your content should
                resonate with your audience, inspire engagement, and drive
                meaningful conversations. This feature is designed to analyze
                and modify users' LinkedIn posts to a tone that is professional
                yet engaging. Whether you're aiming for a polished, corporate
                style or a warm, conversational tone, this feature tailors each
                post to feel natural and relevant. The transformation process
                involves subtle adjustments in language, phrasing, and sentence
                structure to make the content more approachable and credible.
                For example, instead of saying, “I am excited to announce...,”
                our tool might suggest, “I am thrilled to share this update with
                our LinkedIn community,” which strikes a professional and
                inclusive tone. This feature works seamlessly to convert generic
                posts into powerful narratives that capture the attention of
                professionals, potential clients, or hiring managers. The
                result? Content that not only looks professional but also feels
                genuine, compelling, and ready to spark LinkedIn conversations.
              </p>
            </Col>
          </Row>

          <Row className="align-items-center mb-5 flex-md-row-reverse">
            <Col md={6}>
              <img
                src="./f2.webp"
                alt="Optimized Tags for Maximum Engagement"
                className="img-fluid rounded"
              />
            </Col>
            <Col md={6}>
              <h3>Optimized Tags for Maximum Engagement</h3>
              <p>
                Tags are essential for gaining visibility, driving engagement,
                and reaching the right audience on LinkedIn. Our tool’s tag
                optimization feature provides users with an automatic selection
                of powerful tags relevant to their post content. But it doesn’t
                stop there – this feature evaluates the potential impact of each
                tag, choosing ones that are likely to increase engagement, drive
                likes, and attract comments from other LinkedIn users. With this
                feature, users can rest assured that they are reaching the right
                audience. For instance, if a user is posting about leadership,
                the tool might suggest high-traffic tags such as
                #LeadershipTips, #CareerDevelopment, or #GrowthMindset. The tool
                categorizes tags into high, medium, and low traffic, allowing
                users to prioritize impactful tags and build a strategic mix
                that ensures both visibility and relevance. By using the most
                effective tags, users' posts are more likely to appear in
                searches, garner reactions, and invite connections from industry
                leaders, peers, and potential collaborators.
              </p>
            </Col>
          </Row>

          <Row className="align-items-center mb-5">
            <Col md={6}>
              <img
                src="./f3.webp"
                alt="Feature 3"
                className="img-fluid rounded"
              />
            </Col>
            <Col md={6}>
              <h3>Enhanced Keywords for Higher Traffic and Conversations</h3>
              <p>
                Keywords are integral to LinkedIn’s algorithm. Including the
                right keywords in a post can significantly impact its reach,
                leading to more views, profile visits, and even follower growth.
                This feature is designed to extract key phrases from users'
                content and enhance them with high-traffic keywords that are
                trending on LinkedIn. Our keyword enhancement tool also focuses
                on improving the organic flow of these keywords, ensuring that
                posts remain readable and professional. This feature is ideal
                for professionals looking to expand their LinkedIn footprint and
                boost the organic reach of their posts. For example, a post
                discussing digital marketing strategies may automatically
                integrate high-impact keywords like “Digital Transformation,”
                “SEO Best Practices,” or “Content Marketing Trends.” The tool’s
                algorithm identifies keywords with high engagement potential and
                places them strategically throughout the post. This feature not
                only boosts post visibility in search results but also
                encourages interactions, as the keywords are tailored to spark
                curiosity and invite thoughtful responses from other LinkedIn
                members.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Customer Testimonials */}
      <section id="testimonials" className="my-5 bg-light py-5">
        <Container>
          <Row className="text-center mb-4">
            <Col>
              <h2>What Our Customers Say</h2>
              <p>
                Discover how our LinkedIn content tool is empowering
                professionals to achieve more.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm p-3 rounded">
                <Card.Body>
                  <Card.Text>
                    "This tool has transformed the way I create LinkedIn posts.
                    My posts are now more engaging and professional, and I’ve
                    noticed a significant increase in views and interactions!"
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted">
                  - Emily Clark, Marketing Specialist
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm p-3 rounded">
                <Card.Body>
                  <Card.Text>
                    "As a recruiter, the tailored tags and keywords feature has
                    been a lifesaver! My posts now attract top candidates and
                    industry professionals, making my job so much easier."
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted">
                  - Mark Liu, Senior Recruiter
                </Card.Footer>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="h-100 shadow-sm p-3 rounded">
                <Card.Body>
                  <Card.Text>
                    "I wasn’t expecting such a boost in engagement! The
                    suggestions and improvements the tool provides make my
                    content stand out, and it’s reflected in the responses I
                    get."
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-muted">
                  - Rachel Adams, Content Creator
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="my-5">
        <Container>
          <Row className="text-center mb-4">
            <Col>
              <h2>Frequently Asked Questions</h2>
              <p>
                Find answers to our most commonly asked questions about the
                LinkedIn content generation tool.
              </p>
            </Col>
          </Row>
          <Row className="gy-4">
            <Col md={6}>
              <h5>How does your tool optimize LinkedIn content?</h5>
              <p>
                Our tool uses AI algorithms to transform your content into a
                LinkedIn-ready format. It enhances tone, structure, and keywords
                to make your posts more engaging and professional.
              </p>
            </Col>
            <Col md={6}>
              <h5>What type of content improvements does it offer?</h5>
              <p>
                Our tool refines your language, suggests high-traffic keywords,
                and generates effective tags to help your posts reach a larger
                audience and attract more engagement.
              </p>
            </Col>
            <Col md={6}>
              <h5>Can I try the tool before subscribing?</h5>
              <p>
                Yes, we offer a free trial so you can explore the features and
                see the value it brings to your LinkedIn content strategy.
              </p>
            </Col>
            <Col md={6}>
              <h5>How secure is my content and data?</h5>
              <p>
                Data security is a top priority. We utilize advanced encryption
                to ensure that your content and personal data are fully
                protected at all times.
              </p>
            </Col>
            <Col md={6}>
              <h5>Does the tool suggest new tags and keywords?</h5>
              <p>
                Yes, our AI can analyze your content and recommend additional
                tags and keywords tailored to LinkedIn’s high-engagement
                categories, boosting your visibility.
              </p>
            </Col>
            <Col md={6}>
              <h5>What subscription plans do you offer?</h5>
              <p>
                We offer Basic, Pro, and Enterprise plans, each with features
                tailored to different needs, from individual professionals to
                enterprise teams looking to enhance LinkedIn content.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Call to Action */}
      <section className="text-center bg-primary text-white py-5">
        <Container>
          <h2>Ready to Boost Your LinkedIn Engagement?</h2>
          <p>
            Join thousands of professionals who are transforming their LinkedIn
            presence with our AI-driven content tool.
          </p>
          <Button
            variant="light"
            size="lg"
            as={Link}
            to="/contact"
            className="mt-3"
          >
            Contact Us Today
          </Button>
          <p className="mt-4">
            <small>
              Experience professional content that elevates your reach and
              engagement on LinkedIn.
            </small>
          </p>
        </Container>
      </section>

      {/* Footer with Links */}
      <footer className="bg-dark text-light py-4">
        <Container>
          <Row>
            <Col md={4}>
              <h5>SocialMystic</h5>
              <p>Turn Every Post into a Powerful Connection.</p>
            </Col>
            <Col md={4}>
              <h5>Quick Links</h5>
              <Nav className="flex-column">
                <Nav.Link as={Link} to="/" className="text-light">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/about" className="text-light">
                  About Us
                </Nav.Link>
                <Nav.Link as={Link} to="/features" className="text-light">
                  Features
                </Nav.Link>
                <Nav.Link as={Link} to="/contact" className="text-light">
                  Contact Us
                </Nav.Link>
                <Nav.Link as={Link} to="/privacyPolicy" className="text-light">
                  Privacy Policy
                </Nav.Link>
              </Nav>
            </Col>
            <Col md={4}>
              <h5>Contact</h5>
              <p>Email: tekvenza@gmail.com</p>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <small>
                &copy; {new Date().getFullYear()} SocialMystic. All rights
                reserved.
              </small>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default LandingPage;
