import { createBrowserRouter, Navigate } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import AboutPage from "./components/AboutPage";
import FeaturesPage from "./components/Features";
import ContactUsPage from "./components/ContactUsPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import Header from "./components/Header";
import TermsOfService from "./components/TermsOfService";
import NotFound from "./components/NotFound";

const route = createBrowserRouter([
    {
        path: "/",
        element: <LandingPage />
    },
    {
        path: "/about",
        element: (
            <>
                <Header />
                <AboutPage />
            </>
        ),
    },
    {
        path: "/features",
        element: (
            <>
                <Header />
                <FeaturesPage />
            </>
        ),
    },
    {
        path: "/contact",
        element: (
            <>
                <Header />
                <ContactUsPage />
            </>
        ),
    },
    {
        path: "/privacyPolicy",
        element: (
            <>
                <Header />
                <PrivacyPolicyPage />
            </>
        ),
    },
    {
        path: "/termsOfService",
        element: (
            <>
                <Header />
                <TermsOfService />
            </>
        ),
    },
    {
        path: "*",
        element: <NotFound />, // Redirects to LandingPage for unknown routes
    }
]);

export default route;