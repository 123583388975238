// src/components/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>404 - Page Not Found</title>
      </Helmet>
      <h1 style={styles.title}>404 - Page Not Found</h1>
      <p style={styles.message}>The page you are looking for does not exist.</p>
      <Link to="/" style={styles.homeLink}>
        <AiOutlineHome style={styles.icon} /> Go to Home
      </Link>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '20%',
    padding: '20px',
  },
  title: {
    fontSize: '2em',
    color: '#333',
  },
  message: {
    fontSize: '1.2em',
    color: '#666',
  },
  homeLink: {
    fontSize: '1em',
    color: '#007bff',
    textDecoration: 'none',
    marginTop: '20px',
    display: 'inline-flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '8px',
    fontSize: '1.2em',
  },
};

export default NotFound;