import React, { useState } from "react";
import {
  Button,
  Form,
  Spinner,
  Toast,
  ToastContainer,
  Row,
  Col,
} from "react-bootstrap";
import {
  generateContentApi,
  generateContentEmoji,
  getImage,
  getTagsAndKeywords,
} from "../api/contentGenerationApi";
import ImagePreview from "./ImagePreview";
import { FaFire, FaChartLine, FaSeedling, FaRegCopy } from "react-icons/fa";

const ContentGenerator = () => {
  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [tags, setTags] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [loadingContent, setLoadingContent] = useState(false);
  const [loadingTagsKeywords, setLoadingTagsKeywords] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showEmptyContentToast, setShowEmptyContentToast] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [loadingEmoji, setLoadingEmoji] = useState(false);

  const getTrafficIcon = (traffic) => {
    switch (traffic) {
      case "high":
        return <FaFire color="red" title="High Traffic" />;
      case "medium":
        return <FaChartLine color="orange" title="Medium Traffic" />;
      case "low":
        return <FaSeedling color="green" title="Low Traffic" />;
      default:
        return null;
    }
  };

  const sortByTraffic = (arr) => {
    const trafficOrder = { high: 1, medium: 2, low: 3 };
    return arr.sort(
      (a, b) => trafficOrder[a.traffic] - trafficOrder[b.traffic]
    );
  };

  const handleGenerateContent = async () => {
    if (!inputText.trim()) {
      setShowEmptyContentToast(true);
      return;
    }
    setLoadingContent(true);
    try {
      const res = await generateContentApi({ content: inputText });
      const { content = "", tags, keywords } = res?.data;
      setOutputText(content);
      setTags(sortByTraffic(tags || []));
      setKeywords(sortByTraffic(keywords || []));
    } catch (error) {
      console.error("Error generating content:", error);
    } finally {
      setLoadingContent(false);
    }
  };

  const handleGenerateEmoji = async () => {
    if (!inputText.trim()) {
      setShowEmptyContentToast(true);
      return;
    }
    setLoadingEmoji(true);
    try {
      const res = await generateContentEmoji({ content: inputText });
      const { content = "" } = res?.data;
      setOutputText(content);
    } catch (error) {
      console.error("Error generating content:", error);
    } finally {
      setLoadingEmoji(false);
    }
  };

  const handleGenerateTagsKeywords = async () => {
    if (!inputText.trim()) {
      setShowEmptyContentToast(true);
      return;
    }
    setLoadingTagsKeywords(true);
    try {
      const res = await getTagsAndKeywords({ content: inputText });
      const { tags, keywords } = res?.data;
      setTags(sortByTraffic(tags || []));
      setKeywords(sortByTraffic(keywords || []));
    } catch (error) {
      console.error("Error generating tags and keywords:", error);
    } finally {
      setLoadingTagsKeywords(false);
    }
  };

  const handleGenerateImage = async () => {
    if (!inputText.trim()) {
      setShowEmptyContentToast(true);
      return;
    }
    setLoadingImage(true);
    try {
      const res = await getImage({ content: inputText });
      const { url } = res?.data;
      setImageUrl(url);
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
      setLoadingImage(false);
    }
  };

  const handleCopyToClipboard = () => {
    if (outputText.trim()) {
      navigator.clipboard.writeText(outputText);
      setShowToast(true);
    }
  };

  return (
    <div>
      <Row className="text-center mb-4">
        <Col>
          <h1>LinkedIn Post Optimizer</h1>
        </Col>
      </Row>

      <Row className="align-items-center mx-5">
        <Col>
          <Form.Control
            as="textarea"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Paste your LinkedIn post to add high-traffic keywords, tags, and a relevant image..."
            className="textarea-expandable"
            rows={5}
          />
        </Col>
      </Row>

      <Row className="text-center my-4">
        <Col>
          <Button
            variant="primary"
            onClick={handleGenerateContent}
            disabled={loadingContent}
          >
            {loadingContent ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
            ) : (
              "Enhance Content"
            )}
          </Button>
        </Col>
        <Col>
          <Button
            variant="warning"
            onClick={handleGenerateTagsKeywords}
            disabled={loadingTagsKeywords}
          >
            {loadingTagsKeywords ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
            ) : (
              "Get Tags"
            )}
          </Button>
        </Col>
        {/* <Col>
          <Button
            variant="success"
            onClick={handleGenerateImage}
            disabled={loadingImage}
          >
            {loadingImage ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
            ) : (
              "Generate Image"
            )}
          </Button>
        </Col> */}
        <Col>
          <Button
            variant="success"
            onClick={handleGenerateEmoji}
            disabled={loadingEmoji}
          >
            {loadingEmoji ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                className="me-2"
              />
            ) : (
              "Generate Emoji"
            )}
          </Button>
        </Col>
      </Row>

      {outputText && (
        <Row className="align-items-center mx-5 position-relative">
          <Col>
            <div style={{ position: "relative" }}>
              <Form.Control
                as="textarea"
                value={outputText}
                readOnly
                className="textarea-expandable position-relative"
                rows={5}
              />
              <Button
                variant="secondary"
                onClick={handleCopyToClipboard}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  fontSize: "0.8em",
                  padding: "0.25rem 0.5rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem", // Adds some space between text and icon
                }}
              >
                <FaRegCopy /> Copy
              </Button>
            </div>
          </Col>
        </Row>
      )}

      {tags.length > 0 && (
        <Row className="mt-4 mx-5">
          <Col md={6}>
            <h5>Tags</h5>
            <div className="d-flex flex-wrap mb-3 gap-2">
              {tags.map((tag, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#f8f9fa",
                    padding: "4px 8px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                    color: "#333",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getTrafficIcon(tag.traffic)}{" "}
                  <span className="ms-2">{tag.tag}</span>
                </div>
              ))}
            </div>
          </Col>
          <Col md={6}>
            <h5>Keywords</h5>
            <div className="d-flex flex-wrap mb-3 gap-2">
              {keywords.map((keyword, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#f8f9fa",
                    padding: "4px 8px",
                    borderRadius: "5px",
                    border: "1px solid #ddd",
                    color: "#333",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getTrafficIcon(keyword.traffic)}{" "}
                  <span className="ms-2">{keyword.keyword}</span>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      )}
      {(tags.length > 0 || keywords.length > 0) && (
        <Row className="text-center mt-4">
          <Col>
            <h6>Traffic Legend</h6>
            <div className="d-flex justify-content-center gap-3">
              <div className="d-flex align-items-center">
                <FaFire color="red" />{" "}
                <span className="ms-1">High Traffic</span>
              </div>
              <div className="d-flex align-items-center">
                <FaChartLine color="orange" />{" "}
                <span className="ms-1">Medium Traffic</span>
              </div>
              <div className="d-flex align-items-center">
                <FaSeedling color="green" />{" "}
                <span className="ms-1">Low Traffic</span>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {imageUrl && (
        <Row className="mt-4">
          <Col className="text-center">
            <ImagePreview imageUrl={imageUrl} altText="Preview Image" />
          </Col>
        </Row>
      )}
      <ToastContainer
        style={{
          position: "fixed",
          top: "10px", // Distance from the top
          left: "50%", // Center horizontally
          transform: "translateX(-50%)", // Align precisely at the center
          zIndex: 1055, // Ensure visibility above other elements
        }}
      >
        {/* Toast for "Content copied to clipboard" */}
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Body
            style={{
              backgroundColor: "#5c636a", // Black background
              color: "#fff", // White text
              fontWeight: "bold", // Optional for better readability
            }}
          >
            Content copied to clipboard!
          </Toast.Body>
        </Toast>

        {/* Toast for "Please enter some content" */}
        <Toast
          onClose={() => setShowEmptyContentToast(false)}
          show={showEmptyContentToast}
          delay={3000}
          autohide
        >
          <Toast.Body
            style={{
              backgroundColor: "#5c636a", // Black background
              color: "#fff", // White text
              fontWeight: "bold", // Optional for better readability
            }}
          >
            Please enter some content to proceed.
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default ContentGenerator;
