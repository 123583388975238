import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const TermsOfService = () => {
  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="mb-4">Terms of Service</h1>
          <p>Welcome to <strong>SocialMystic</strong>! These Terms of Service outline the rules and regulations for using our platform. By accessing our website or using our services, you agree to be bound by these terms. Please review them carefully to understand your rights and obligations.</p>
          
          <h3>1. Acceptance of Terms</h3>
          <p>By accessing or using our website, tools, or services, you accept and agree to comply with these Terms of Service, along with any other guidelines, policies, or rules that we may provide. If you do not agree to any of these terms, please refrain from using our services.</p>
          
          <h3>2. Modifications to Terms</h3>
          <p>We reserve the right to amend or modify these Terms of Service at any time. Any changes will be posted on this page and are effective immediately upon posting. Your continued use of our services following any changes indicates your acceptance of the revised terms. It is your responsibility to review these terms periodically to stay informed of updates.</p>
          
          <h3>3. Services Provided</h3>
          <p><strong>SocialMystic</strong> offers advanced AI-driven content generation services to help users create professional, engaging posts for LinkedIn and other platforms. Our tools provide features such as optimized tags, keyword enhancement, real-time content suggestions, and analytics.</p>
          
          <h3>4. User Responsibilities and Conduct</h3>
          <p>You agree to use our site and services for lawful purposes only. You are solely responsible for the content you generate and share using our platform. By using our tool, you agree not to:</p>
          <ul>
            <li>Engage in any activity that may interfere with or disrupt our services.</li>
            <li>Attempt to gain unauthorized access to our servers or networks.</li>
            <li>Transmit any content that is illegal, defamatory, abusive, or otherwise objectionable.</li>
            <li>Misrepresent yourself or your affiliations with any person or entity.</li>
          </ul>
          
          <h3>5. Intellectual Property Rights</h3>
          <p>All content on our website, including but not limited to logos, graphics, software, and text, is the intellectual property of <strong>SocialMystic</strong> and is protected by applicable intellectual property laws. You may not use, reproduce, or distribute any content on this website without explicit written permission from us.</p>
          <p>Any content you create using our services is solely your responsibility, and you hold all intellectual property rights to the original content you generate. However, we reserve the right to use anonymized, aggregated data for analytical and service improvement purposes.</p>
          
          <h3>6. Privacy and Data Protection</h3>
          <p>Your privacy is important to us. We are committed to protecting the personal data you share with us as outlined in our Privacy Policy. By using our services, you consent to the collection, use, and storage of your information in accordance with our Privacy Policy.</p>
          <p>If you do not agree with the practices described in our Privacy Policy, please do not use our services. For any questions or concerns about your data, contact us through the provided communication channels.</p>
          
          <h3>7. User-Generated Content</h3>
          <p>You are responsible for the content you create and share using our tool. While we may offer suggestions, tags, and keywords to enhance your content, you are solely accountable for ensuring that the content complies with all applicable laws and is free from infringement, plagiarism, or defamation.</p>
          <p>We do not claim ownership of your generated content, but by using our services, you grant us a limited license to use, store, and display your content for operational purposes.</p>
          
          <h3>8. Third-Party Services and Links</h3>
          <p>Our website may contain links to third-party services or resources that are not owned or controlled by us. We are not responsible for the content, privacy policies, or practices of third-party websites or services. We recommend that you review the terms of use and privacy policies of any third-party sites before engaging with them.</p>
          
          <h3>9. Limitation of Liability</h3>
          <p>In no event shall <strong>SocialMystic</strong> be liable for any damages, including but not limited to direct, indirect, incidental, or consequential damages, arising out of your use or inability to use our services. This limitation applies even if we have been advised of the possibility of such damages.</p>
          <p>We make no warranties, either express or implied, regarding the functionality, reliability, or accuracy of our content generation tool. The service is provided on an "as is" and "as available" basis, and you use it at your own risk.</p>
          
          <h3>10. Indemnification</h3>
          <p>You agree to indemnify and hold <strong>SocialMystic</strong> and its employees, contractors, and affiliates harmless from any claims, damages, losses, or expenses arising from your use of our services, violation of these Terms of Service, or infringement of any rights of another party.</p>
          
          <h3>11. Termination</h3>
          <p>We reserve the right to terminate or suspend your access to our services, without notice, for any conduct that we, in our sole discretion, believe is disruptive or in violation of these Terms of Service or any applicable law.</p>
          <p>Upon termination, all provisions of these terms that by their nature should survive termination shall remain in effect, including intellectual property rights, limitation of liability, and indemnification.</p>
          
          <h3>12. Governing Law and Dispute Resolution</h3>
          <p>These terms and any disputes arising from them are governed by the laws of <strong>TamilNadu, India</strong>. Any disputes or claims related to your use of our services shall be resolved through binding arbitration in <strong>TamilNadu, India</strong> unless otherwise agreed by both parties. Each party will be responsible for its own costs in the arbitration process.</p>
          
          <h3>13. Contact Information</h3>
          <p>If you have any questions or concerns regarding these Terms of Service, please <a href="/contact">contact us</a>. We welcome your feedback and are here to address any issues you may have regarding our services or terms.</p>
        
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfService;
