import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_CONTNET_GENRATION_API}`;
const GET_TAGS_AND_KEYWORDS = `${process.env.REACT_APP_GET_TAGS_AND_KEYWORDS}`;
const GENERATE_IMAGE = `${process.env.REACT_APP_GENERATE_IMAGE}`;
const GENERATE_EMOJI = `https://8g4vn7k10h.execute-api.ap-south-1.amazonaws.com/prod/generateEmoji`;

export const generateContentApi = async (req) => {
    return await axios.post(BASE_URL, { ...req});
}

export const getTagsAndKeywords = async (req) => {
    return await axios.post(GET_TAGS_AND_KEYWORDS, { ...req});
}

export const getImage = async (req) => {
    return await axios.post(GENERATE_IMAGE, { ...req});
}

export const generateContentEmoji = async (req) => {
    return await axios.post(GENERATE_EMOJI, {...req});
}